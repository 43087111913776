/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb } from '@dpa/ui-common';
import { Action, createReducer, on } from '@ngrx/store';

import { BlockerStatus } from '@ws1c/intelligence-models';
import { NavigationActions } from './navigation.actions';
import { initialNavigationState, NavigationState } from './navigation.state';

const _reducer = createReducer(
  initialNavigationState,
  on(
    NavigationActions.setBreadCrumbs,
    (state: NavigationState, { breadCrumbs }: { breadCrumbs: BreadCrumb[] }): NavigationState => ({
      ...state,
      breadCrumbs,
    }),
  ),

  on(
    NavigationActions.setPageTitle,
    (state: NavigationState, { pageTitle }: { pageTitle: string }): NavigationState => ({
      ...state,
      pageTitle,
    }),
  ),

  on(
    NavigationActions.setBlockerStatus,
    (state: NavigationState, { blockerStatus }: { blockerStatus: BlockerStatus }): NavigationState => ({
      ...state,
      blockerStatus,
      pendingMessage: blockerStatus === BlockerStatus.APPROVED ? '' : state.pendingMessage,
    }),
  ),

  on(
    NavigationActions.setReturnPath,
    (state: NavigationState, { returnPath }: { returnPath: string }): NavigationState => ({
      ...state,
      returnPath,
    }),
  ),

  on(
    NavigationActions.setPendingMessage,
    (state: NavigationState, { pendingMessage }: { pendingMessage: string }): NavigationState => ({
      ...state,
      pendingMessage,
    }),
  ),

  on(
    NavigationActions.setPreviewModalTitle,
    (state: NavigationState, { modalTitle }: ReturnType<typeof NavigationActions.setPreviewModalTitle>): NavigationState => ({
      ...state,
      previewModalTitle: modalTitle,
    }),
  ),

  on(
    NavigationActions.showPreviewModal,
    (
      state: NavigationState,
      { type: _type, ...previewModalData }: ReturnType<typeof NavigationActions.showPreviewModal>,
    ): NavigationState => ({
      ...state,
      isPreviewModalOpen: true,
      previewModalData,
    }),
  ),

  on(
    NavigationActions.hidePreviewModal,
    (state: NavigationState): NavigationState => ({
      ...state,
      isPreviewModalOpen: false,
    }),
  ),
);

/**
 * NavigationState Reducer
 * @param {NavigationState} state
 * @param {Action} action
 * @returns {NavigationState}
 */
export function navigationState(state: NavigationState, action: Action) {
  return _reducer(state, action);
}
