/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BreadCrumb } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { BlockerStatus, NavigationPreviewModalData } from '@ws1c/intelligence-models';

/**
 * NavigationActions
 * @export
 * @class NavigationActions
 */
@Injectable()
export class NavigationActions {
  public static readonly CATEGORY = '[NAVIGATION]';

  public static setBreadCrumbs = createAction(`${NavigationActions.CATEGORY} SET_BREADCRUMBS`, props<{ breadCrumbs: BreadCrumb[] }>());
  public static setPageTitle = createAction(`${NavigationActions.CATEGORY} SET_PAGE_TITLE`, props<{ pageTitle: string }>());
  public static setBlockerStatus = createAction(
    `${NavigationActions.CATEGORY} SET_BLOCKER_STATUS`,
    props<{ blockerStatus: BlockerStatus }>(),
  );
  public static setReturnPath = createAction(`${NavigationActions.CATEGORY} SET_RETURN_PATH`, props<{ returnPath: string }>());
  public static setPendingMessage = createAction(`${NavigationActions.CATEGORY} SET_PENDING_MESSAGE`, props<{ pendingMessage: string }>());
  public static showPreviewModal = createAction(`${NavigationActions.CATEGORY} SHOW_PREVIEW_MODAL`, props<NavigationPreviewModalData>());
  public static hidePreviewModal = createAction(`${NavigationActions.CATEGORY} HIDE_PREVIEW_MODAL`);
  public static setPreviewModalTitle = createAction(
    `${NavigationActions.CATEGORY} SET_PREVIEW_MODAL_TITLE`,
    props<{ modalTitle: string }>(),
  );
}
