/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { BlockerStatus, NavigationPreviewModalData, NavigationPreviewModalType } from '@ws1c/intelligence-models';
import { NavigationState } from './navigation.state';

/**
 * NavigationSelectors
 *
 * @export
 * @class NavigationSelectors
 */
export class NavigationSelectors {
  /**
   * selectNavigationState
   *
   * @static
   * @param {CoreAppState} state
   * @returns {NavigationState}
   * @memberof NavigationSelectors
   */
  public static selectNavigationState = (state: CoreAppState) => state.navigationState;

  /**
   * getNavigationBlockerStatus
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, BlockerStatus>}
   * @memberof NavigationSelectors
   */
  public static getNavigationBlockerStatus: MemoizedSelector<CoreAppState, BlockerStatus> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.blockerStatus,
  );

  /**
   * getNavigationReturnPath
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationSelectors
   */
  public static getNavigationReturnPath: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.returnPath,
  );

  /**
   * getPendingMessage
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationSelectors
   */
  public static getPendingMessage: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.pendingMessage,
  );

  /**
   * getConfirmLeaveModalOpen
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationSelectors
   */
  public static getConfirmLeaveModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationSelectors.getNavigationBlockerStatus,
    (routeblockerStatus: BlockerStatus) => routeblockerStatus === BlockerStatus.PENDING_APPROVAL,
  );

  /**
   * getBreadCrumbs
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, BreadCrumb[]>}
   * @memberof NavigationSelectors
   */
  public static getBreadCrumbs: MemoizedSelector<CoreAppState, BreadCrumb[]> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.breadCrumbs,
  );

  /**
   * getPageTitle
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationSelectors
   */
  public static getPageTitle: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.pageTitle,
  );

  /**
   * getPreviewModalData
   * @static
   * @type {MemoizedSelector<CoreAppState, NavigationPreviewModalData>}
   * @memberof NavigationSelectors
   */
  public static getPreviewModalData: MemoizedSelector<CoreAppState, NavigationPreviewModalData> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.isPreviewModalOpen && state.previewModalData,
  );

  /**
   * getPreviewModalTitle
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationSelectors
   */
  public static getPreviewModalTitle: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.previewModalTitle,
  );

  /**
   * isPreviewModalOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationSelectors
   */
  public static isPreviewModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationSelectors.selectNavigationState,
    (state: NavigationState) => state.isPreviewModalOpen,
  );

  /**
   * isPreviewModalOpenByType
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationSelectors
   */
  public static isPreviewModalOpenByType = (modalType: NavigationPreviewModalType): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      NavigationSelectors.selectNavigationState,
      (state: NavigationState) => state.previewModalData.modalType === modalType && state.isPreviewModalOpen,
    );
}
