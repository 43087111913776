/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb } from '@dpa/ui-common';

import { BlockerStatus, NavigationPreviewModalData } from '@ws1c/intelligence-models';

/**
 * NavigationState
 * @export
 * @interface NavigationState
 */
export interface NavigationState {
  blockerStatus: BlockerStatus;
  breadCrumbs: BreadCrumb[];
  pageTitle: string;
  pendingMessage: string;
  returnPath: string;
  isPreviewModalOpen: boolean;
  previewModalTitle: string;
  previewModalData: NavigationPreviewModalData;
}

/**
 * initialNavigationState
 */
export const initialNavigationState: NavigationState = {
  blockerStatus: BlockerStatus.BLOCKING,
  breadCrumbs: [],
  pageTitle: '',
  pendingMessage: '',
  returnPath: '',
  isPreviewModalOpen: false,
  previewModalTitle: '',
  previewModalData: {
    modalType: undefined,
    resourceId: '',
    resourceSourceObject: undefined,
  },
};
